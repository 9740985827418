const Psychologydata = [
  {
    _id: '65c8fa7e0c83d10491afb203',
    name: 'UCL',
    country: 'United Kingdom',
    city: 'England',
    address: 'University of Warwick, Coventry CV4 7AL, UK',
    Qsranking: 24,
    Guardianranking: 1,
    website: 'https://www.ucl.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f7f10c83d10491afb1f9',
    name: 'University of Oxford',
    country: 'United Kingdom',
    city: 'England',
    address: 'The Old Schools, Trinity Ln, Cambridge CB2 1TN, United Kingdom',
    Qsranking: 9,
    Guardianranking: 2,
    website: 'https://www.ox.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f8690c83d10491afb1fb',
    name: 'London School of Economics',
    country: 'United Kingdom',
    city: 'London',
    address: 'Houghton St, London WC2A 2AE, United Kingdom',
    Qsranking: 7,
    Guardianranking: 3,
    website: 'https://www.lse.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f6f70c83d10491afb1f4',
    name: 'University of St Andrews',
    country: 'United Kingdom',
    city: 'Scotland',
    address: 'St Andrews KY16 9AJ, United Kingdom',
    Qsranking: 151,
    Guardianranking: 4,
    website: 'https://www.st-andrews.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8fa0e0c83d10491afb201',
    name: 'Lancaster University',
    country: 'United Kingdom',
    city: 'England',
    address: 'Claverton Down, Bath BA2 7AY, United Kingdom',
    Qsranking: 124,
    Guardianranking: 5,
    website: 'https://www.lancaster.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8fb440c83d10491afb207',
    name: 'University of Surrey',
    country: 'United Kingdom',
    city: 'England',
    address: 'Epinal Way, Loughborough LE11 3TU, United Kingdom',
    Qsranking: 251,
    Guardianranking: 6,
    website: 'https://www.surrey.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f9760c83d10491afb1ff',
    name: 'Durham University',
    country: 'United Kingdom',
    city: 'England',
    address: 'The Palatine Centre, University, Stockton Rd, Durham DH1 3LE, UK',
    Qsranking: 151,
    Guardianranking: 7,
    website: 'https://www.durhamisc.com/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f8f60c83d10491afb1fd',
    name: 'University of Warwick',
    country: 'United Kingdom',
    city: 'London',
    address: 'Imperial College London,Exhibition Road, London SW7 2AZ',
    Qsranking: 22,
    Guardianranking: 8,
    website: 'https://warwick.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8fada0c83d10491afb205',
    name: 'University of Leicester',
    country: 'United Kingdom',
    city: 'England',
    address: 'Gower St, London WC1E 6BT, United Kingdom',
    Qsranking: 451,
    Guardianranking: 9,
    website: 'https://le.ac.uk/',
    coursesOffered: [],
    __v: 0
  },
  {
    _id: '65c8f7920c83d10491afb1f7',
    name: 'University of Cambridge',
    country: 'United Kingdom',
    city: 'England',
    address: 'Wellington Square, Oxford OX1 2JD, United Kingdom',
    Qsranking: 12,
    Guardianranking: 10,
    website: 'https://www.cam.ac.uk/',
    coursesOffered: [],
    __v: 0
  }
]


export default Psychologydata;