import React from "react";

const ProductDetail = () => {
  return (
    <>
    <div className="mt-40">Product details</div>
    </>
  );
};

export default ProductDetail;
