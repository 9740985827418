import React from "react";
import Navbar2 from "../../Components/Navbar2/Navbar2";
import { Footer } from '../../Components/Footer/Footer';
import ProductDetail from "../../Components/ProductDetail/ProductDetail";

const ProductDetailPg = () => {
  return (
    <>
    <Navbar2 />
    <ProductDetail />
    <Footer />
    </>
  );
};

export default ProductDetailPg;
