import React from "react";

function DownloadPDF4() {
    return(
        <div style={{ backgroundColor: '#2563EB' }} className="text-white font-medium rounded-lg text-sm px-5 py-2.5 w-40 mt-3 flex justify-center mx-auto" >
            <a href="Template.txt" target="_blank" rel="noopener noreferrer">Download</a>
        </div>
    )
}
export default DownloadPDF4
