import React from "react";
import Navbar2 from "../../Components/Navbar2/Navbar2";
import { Footer } from '../../Components/Footer/Footer';
// import UniMarketForm from "../../Components/UniMarketForm/UniMarketForm";

const UniMarketFormPg = () => {
  return (
    <>
    <Navbar2 />
    {/* <UniMarketForm /> */}
    <Footer />
    </>
  );
};

export default UniMarketFormPg;
