import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { Footer } from '../../Components/Footer/Footer'
import FindMyFriend from '../../Components/FindmyFriend/FindMyFriend'

const FindmyFriend = () => {
    return (
        <>
            <Navbar />
            <FindMyFriend/>
            <Footer />
        </>
    )
}

export default FindmyFriend