import React from "react";

const Message = () => {
    return (
        <>
        <div className="mt-40">chat pg</div>
        </>
    )
}

export default Message;