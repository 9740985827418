import React from "react";
import Navbar2 from "../../Components/Navbar2/Navbar2";
import { Footer } from '../../Components/Footer/Footer';
import Admin from "../../Components/Admin/Admin";

const AdminPg = () => {
    return (
        <>
        <Navbar2 />
        <Admin />
        <Footer />
        </>
    )
}

export default AdminPg;