const buisnessdata = [
  {
    _id: "65c8f6f70c83d10491afb1f4",
    name: "University of Oxford",
    country: "United Kingdom",
    city: "Scotland",
    address: "St Andrews KY16 9AJ, United Kingdom",
    Qsranking: 9,
    Guardianranking: 1,
    website: "https://www.ox.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8f7920c83d10491afb1f7",
    name: "University of St Andrews",
    country: "United Kingdom",
    city: "England",
    address: "Wellington Square, Oxford OX1 2JD, United Kingdom",
    Qsranking: 301,
    Guardianranking: 2,
    website: "https://www.st-andrews.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8fa7e0c83d10491afb203",
    name: "University of Warwick",
    country: "United Kingdom",
    city: "England",
    address: "University of Warwick, Coventry CV4 7AL, UK",
    Qsranking: 22,
    Guardianranking: 3,
    website: "https://warwick.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8f8690c83d10491afb1fb",
    name: "The London School of Economics and Political Science (LSE)",
    country: "United Kingdom",
    city: "London",
    address: "Houghton St, London WC2A 2AE, United Kingdom",
    Qsranking: 11,
    Guardianranking: 4,
    website: "https://onlinecourses.london.ac.uk/",
    coursesOffered: [],
    __v: 0,
  }, 
  {
    _id: "65c8f9760c83d10491afb1ff",
    name: "UCL",
    country: "United Kingdom",
    city: "England",
    address: "The Palatine Centre, University, Stockton Rd, Durham DH1 3LE, UK",
    Qsranking: 101,
    Guardianranking: 5,
    website:
      "https://www.ucl.ac.uk/prospective-students/graduate/masters-matter",
    coursesOffered: [],
    __v: 0,
  }, 
  {
    _id: "65c8fb440c83d10491afb207",
    name: "University of Bath",
    country: "United Kingdom",
    city: "England",
    address: "Epinal Way, Loughborough LE11 3TU, United Kingdom",
    Qsranking: 78,
    Guardianranking: 6,
    website: "https://www.bath.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8fa0e0c83d10491afb201",
    name: "Loughborough University",
    country: "United Kingdom",
    city: "England",
    address: "Claverton Down, Bath BA2 7AY, United Kingdom",
    Qsranking: 135,
    Guardianranking: 7,
    website: "https://www.lboro.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8fa7e0c83d10491afb203",
    name: "University of Glasgow",
    country: "United Kingdom",
    city: "England",
    address: "University of Warwick, Coventry CV4 7AL, UK",
    Qsranking: 137,
    Guardianranking: 8,
    website: "https://www.gla.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8fada0c83d10491afb205",
    name: "King's College London",
    country: "United Kingdom",
    city: "England",
    address: "Gower St, London WC1E 6BT, United Kingdom",
    Qsranking: 97,
    Guardianranking: 9,
    website: "https://www.kcl.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
  {
    _id: "65c8fb440c83d10491afb207",
    name: "University of Leeds",
    country: "United Kingdom",
    city: "England",
    address: "Epinal Way, Loughborough LE11 3TU, United Kingdom",
    Qsranking: 67,
    Guardianranking: 10,
    website: "https://www.leeds.ac.uk/",
    coursesOffered: [],
    __v: 0,
  },
];

export default buisnessdata;
