import bath from "../../Universitylogo/university-of-bath-blue.svg"
import manchester from "../../Universitylogo/manchester.png" 
import Bristol from "../../Universitylogo/bristol.png"

export const data = [
  {
    isActive: true,
    name: "Nia Quinn",
    hireDate: "2016-02-23T18:25:43.511Z",
    age: 42,
    salary: 100_000,
    university: "University of Bath", 
    logo:bath,
    view: "https://www.linkedin.com/in/niaquinn0404/",
  },
  {
    isActive: true,
    name: "Isobel Shone",
    hireDate: "2019-02-23T18:21:43.335",
    age: 51,
    salary: 80_000, 
    logo:bath,
    university: "University of Bath",
    view: "https://www.linkedin.com/in/isobel-shone-50b55a248/",
  },
  {
    isActive: false,
    name: "Jack Ingram",
    hireDate: "2014-02-23T18:25:43.511Z",
    age: 27,
    salary: 120_000, 
    logo:bath,
    university: "University of Bath",
    view: "https://www.linkedin.com/in/-jack-ingram/?originalSubdomain=uk",
  },
  {
    isActive: true,
    name: "Charlie Nicol",
    hireDate: "2015-02-25T18:25:43.511Z",
    age: 32,
    salary: 150_000,
    university: "University of Bath", 
    logo:bath,
    view: "https://www.linkedin.com/in/charlie-nicol-8a3727204/?originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Manya Sharma",
    hireDate: "2023-06-11T18:25:43.511Z",
    age: 42,
    salary: 75_000,
    university: "The University of Manchester", 
    logo:manchester,
    view: "https://www.linkedin.com/in/manya-sharma-b56a3b252/?originalSubdomain=uk",
  },
  {
    isActive: true,
    name: "Molly Sullivan",
    hireDate: "2019-02-23T18:21:43.335",
    age: 51,
    salary: 56_000,
    university: "The University of Manchester", 
    logo:manchester,
    view: "https://www.linkedin.com/in/molly-sullivan-46b776277/?originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Rimsha Afzal Kausar",
    hireDate: "2010-02-23T18:25:43.511Z",
    age: 27,
    salary: 90_000,
    university: "The University of Manchester", 
    logo:manchester,
    view: "https://linkedin.com/in/rimsha-afzal-kausar/?originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Hannah Smith",
    hireDate: "2010-02-23T18:25:43.511Z",
    age: 27,
    salary: 90_000,
    university: "University of Bristol", 
    logo:Bristol,
    view: "https://www.linkedin.com/in/hannah-smith-78aa281a0/?originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Natania Chng",
    hireDate: "2010-02-23T18:25:43.511Z",
    age: 27,
    salary: 90_000,
    university: "University of Bristol",
    logo:Bristol,
    view: "https://www.linkedin.com/in/nataniachng/?trk=org-employees&originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Rebecca Biggin",
    hireDate: "2010-02-23T18:25:43.511Z",
    age: 27,
    salary: 90_000,
    university: "University of Bristol", 
    logo:Bristol,
    view: "https://www.linkedin.com/in/rebecca-biggin-b71368206/?originalSubdomain=uk",
  },
  {
    isActive: false,
    name: "Emily Burt",
    hireDate: "2010-02-23T18:25:43.511Z",
    age: 27,
    salary: 90_000,
    university: "University of Bristol", 
    logo:Bristol,
    view: "https://www.linkedin.com/in/emily-burt01/?originalSubdomain=uk",
  },
];
// export const data = [
//   {
//     isActive: true,
//     name: "Tanner Linsley",
//     hireDate: "2016-02-23T18:25:43.511Z",
//     age: 42,
//     salary: 100_000,
//     university: "San Francisco",
//     view: "California",
//   },
//   {
//     isActive: true,
//     name: "Kevin Vandy",
//     hireDate: "2019-02-23T18:21:43.335",
//     age: 51,
//     salary: 80_000,
//     university: "Richmond",
//     view: "Virginia",
//   },
//   {
//     isActive: false,
//     name: "John Doe",
//     hireDate: "2014-02-23T18:25:43.511Z",
//     age: 27,
//     salary: 120_000,
//     university: "Riverside",
//     view: "South Carolina",
//   },
//   {
//     isActive: true,
//     name: "Jane Doe",
//     hireDate: "2015-02-25T18:25:43.511Z",
//     age: 32,
//     salary: 150_000,
//     university: "San Francisco",
//     view: "California",
//   },
//   {
//     isActive: false,
//     name: "John Smith",
//     hireDate: "2023-06-11T18:25:43.511Z",
//     age: 42,
//     salary: 75_000,
//     university: "Los Angeles",
//     view: "California",
//   },
//   {
//     isActive: true,
//     name: "Jane Smith",
//     hireDate: "2019-02-23T18:21:43.335",
//     age: 51,
//     salary: 56_000,
//     university: "Blacksburg",
//     view: "Virginia",
//   },
//   {
//     isActive: false,
//     name: "Samuel Jackson",
//     hireDate: "2010-02-23T18:25:43.511Z",
//     age: 27,
//     salary: 90_000,
//     university: "New York",
//     view: "New York",
//   },
// ];

export const citiesList = [
  "San Francisco",
  "Richmond",
  "Riverside",
  "Los Angeles",
  "Blacksburg",
  "New York",
];

export const usviewList = [
  "California",
  "Virginia",
  "South Carolina",
  "New York",
  "Texas",
];
